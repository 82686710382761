.alert {
  margin: $grid-desktop-margin;
}

.alert-info {
  background-color: $primary-3;
  color: #FFF;
  border: none;
}

.close {
  color: #FFF;
  opacity: 1;
  &:hover {
    color: #FFF;
    opacity: 1;
  }
}