/* Navbar Styles */
.horizontal-row {
  @extend .flex;
  @extend .flex-horizontal;
  @extend .justify-content-space-between;
  width: $layout_width - 20px;
  height: $shape_primary_size;
  transition: all 0.5s ease;
}

.mdl-layout__header-row .mdl-navigation.vertical {
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.mdl-layout__header-row .mdl-navigation {
  width: $layout_width - 20px;
  height: auto;
  justify-content: space-between;
  transition: all 0.5s ease;
}

.mdl-layout__header-row .mdl-navigation.filter {
  padding: 5px 0;
}

nav a {
  position: relative;
  display: inline-block;
  margin: 0;
  outline: none;
  letter-spacing: 1px;
}

nav a:hover,
nav a:focus {
  outline: none;
}

a, .lnk {
  text-decoration: none;
  cursor: pointer;
}

a:hover {

}

.specialResponsive {
  display: none;
}

#dashboard {
  .mdl-layout__drawer {
    background: $primary-1;
    .header-logo {
      width: 100%;
    }
  }
}

.mdl-layout__drawer {
  background: #DEDEDE;
}


.mdl-layout__drawer .mdl-navigation {
  a {
    padding: 5px 10px;
  }
}

@media screen and (max-width: 1026px) {
  .mdl-layout__header {
    width: 100%;
    display: block;
    .mdl-layout__header-row {
      height: 150px;
    }
    .header-graphic {
      width: 100%;
      .horizontal-row {
        width: 100%;
      }
    }
  }

  .mdl-layout__header .mdl-navigation {
    display: none;
  }
}

@media screen and (min-width: 1027px) {
  .mdl-layout__drawer-button {
    display: none;
  }
}

@media screen and (max-width: 1026px) {
  #site .mdl-layout__header .mdl-layout__header-row {
    height: 150px;
  }
}





