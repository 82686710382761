#site {
  .mdl-layout__header {
    z-index: 1;
  }

  .mdl-layout__header-row {
    height: 210px;
    justify-content: flex-end;
    padding: 0;
    align-items: center;
    flex-flow: column;
    background-color: transparent;
  }

  .mdl-layout__content {
    width: 100%;
    @include breakpoint($medium) {
      width: $layout_width;
    }
    margin: 15px auto 0 auto;
    @extend .flex;
    flex-shrink: 0;
  }
}


.content {
  margin: 10px;
}

.mdl-menu__outline {
  border-radius: 0;
}

footer {
  flex: 0 0 70px;
  background: $bg;
  display: flex;
  flex-direction: row;
  @extend .justify-content-space-between;
  @extend .align-items-flex-end;
  padding-bottom: 20px;
}

.footer-container {
  @extend .flex;
  @extend .justify-content-space-between;
  @extend .align-items-flex-end;
  height: 25px;
  width: $layout_width - 20px;
  margin: 0 auto;
}

.footer-container div {
  margin-top: 0.15em;
}

footer p {
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__content {
    width: 100%;
  }

  .footer-container {
    width: 100%;
    margin: 0 20px;
  }

  .grid-content {
    width: 100%;
  }

  .full-viewport-content {
    flex-flow: column nowrap;
  }

  .full-viewport-content .content {
    margin: 20px;
  }

}