#login {
	background-color: $bg;
	&.content-container {
		max-width: 300px;
		position: fixed;
		top: 50%;
		left: 50%;
		/* bring your own prefixes */
		transform: translate(-50%, -50%);
		.form-container {
			.input {
				margin-bottom: 16px;
			}
			.label {
				font-weight: bold;
				color: $primary-1;
			}
		}
	}
}