/*
TYPOGRAPHY
*/

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/*FONTS*/
/*All fonts to try*/

/*'Old Standard TT', serif;
'Arapey', serif;
'Merriweather', serif;
'Muli', sans-serif;
'fanwoodregular', sans-serif;
'Raleway', sans-serif;
'Lato', sans-serif;*/

$primary-font: 'Tex Gyre Heros', sans-serif;

body {
    -webkit-font-smoothing: subpixel-antialiased;
	font-family: $primary-font;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 16px;
}

h1, h2, h3, h4, h5, h6, nav a {
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: $primary-font;
}

h1, h2, h3, nav a {
  text-transform: uppercase;
}

h6 {
  text-transform: capitalize;
}

h1, h4, h5, h6 {
  margin: 0;
}

.horizontal-row h4 {
  padding-left: 5px;
}

h1 {
  font-size: 40px;
  font-weight: 600;
}

h4, h5, h6 {
  @extend .weight-600;
}

h4 {
  font-size: $shape_primary_size - 6px;
  line-height: $shape_primary_size + 1px;
}

h5 {
  font-size: 23px;
}

h6 {
  line-height: 16px;
}

a:hover, a:focus {
  color: inherit;
  text-decoration: none;
}

.mdl-navigation a {
  @extend .weight-600;
  font-size: 16px;
  line-height: 16px;
}

.mdl-navigation.vertical a {
  line-height: 20px;
  text-transform: capitalize;
}

.mdl-navigation.filter {
  margin-top: 4px;
}

a, p {
  font-size: 16px;
  @extend .weight-300;
}

p {
  margin-bottom: 10px;
  line-height: 16px;
}

.mdi {
  font-size: 18px;
}

.lnk {
  font-size: 14px;
  @extend .text-uppercase;
}

.lnk-back {
  padding: 5px 10px;
  line-height: 14px;
  text-transform: uppercase;
}

.lnk-back-to-top {
  text-align: center;
}

h1 {
  text-transform: none;
  font-size: 60px;
}

.bio, .bio a {
  /*-webkit-hyphens:manual;
  -moz-hyphens:manual;
  hyphens:manual;
  text-align: justify;*/
  font-size: 14px;
  margin-bottom: 0;
}

.campaign {
  margin-top: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.play.material-icons {
  font-size: 30px;
}

.excerpt {
  font-size: 14px;
  padding-top: 5px;
  a {
    font-size: 14px;
    font-weight: bold;
  }
}

.copyright {
  font-size: 14px;
  @extend .weight-300;
  color: lighten($body, 30%);
}

.additional-photography, .lnk-photography {
  font-size: 14px;
}

.posted-at {
  font-size: 12px;
  @extend .text-uppercase;
}

.news-item,
.news-item a,
.news-item p {
  /*@extend .text-justify;*/
  font-size: 14px;
  margin-bottom: 0;
}


/*.news-body br {
  content: "A" !important;
  display: block !important;
  margin-bottom: 10px !important;
}*/

.press a {
  font-size: 14px;
}

#contact span, p, a {
  font-size: 14px;
  line-height: 17px;
}

.chat,
.chat span,
.chat p,
.chat a {
  font-weight: 700;
}

#contact .material-icons {
  font-size: 12px;
  vertical-align: middle;
  margin-bottom: 2px;
}

#contact .contact-sub-header {
  @extend .weight-600;
  @extend .text-uppercase;
}

.contact-item {
  font-weight: 600;
  a, span {
    font-weight: 600;
    font-size: 14px;
  }
}

#contact .locations {
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 45px;
  text-align: center;
}

.mdl-menu a {
  margin: 10px;
}

@media screen and (max-width: 760px) {
  .mdi {
    font-size: 22px;
  }

  #contact span {
    font-size: 16px;
    line-height: 18px;
  }

  #contact .material-icons {
    font-size: 16px;
    vertical-align: middle;
    margin-bottom: 2px;
  }

  .contact-item {
    font-weight: 600;
    a, span {
      font-weight: 600;
      font-size: 16px;
    }
  }
}