.portfolio-container {
  width: 100%;
  margin: 10px auto 30px auto;
}

.img-content {
  width: $layout-width-margin;
  margin: 0 auto 10px auto;
}



.img-prev {
  @extend .opacity-100;
  height: 50px;
  width: 100px;
  float: left;
}

.img-next {
  @extend .opacity-100;
  height: 50px;
  width: 100px;
  float: right;
  text-align: right;
}

.img-prev:hover, .img-next:hover {
  @extend .opacity-0;
}

.img-container {
  position: relative;
  width: $layout-width-margin;
  img {
    width: 100%;
  }
  .img-nav {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 900000;
  }

  .img-nav .material-icons {
    margin-top: -50px;
    font-size: 50px;
    color: #FFF;
  }
}

.img-container img {

}

.portfolio-title {
  @extend .flex;
  @extend .flex-vertical;
}

.link-container {
  @extend .flex;
  @extend .align-items-flex-end;
}

.prev-next-container {
  padding-bottom: 3px;
}

.portfolio-navigation .link-container .mdi,
.portfolio-navigation {
  margin: 0 auto;
  width: calc(100% - 20px);
}

.others {
  margin: 10px auto;
  width: 100%;
}

.player-divider {
  @extend .marg-0-auto;
}

.others-divider {
  width: $layout-width-margin;
  @extend .border-bottom-1px;
  @extend .marg-0-auto;
}

.others .mdl-cell {
  margin: 10px;
}

@media screen and (max-width: 1024px) {
  .img-container {
    position: absolute;
    width: calc(100% - 32px);
    left: 16px;
  }
  .portfolio-navigation {
    width: calc(100% - 32px);
  }
}