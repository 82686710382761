#about.content {
  @extend .flex;
  margin-top: 30px;
  a:hover {
    color: $accent-1;
  }
  a {
    color: $primary-1;
  }
}

#about h5 {
  @extend .marg-btm-20px;
}

.bio {
  @extend .flex-1;
}

.profile-img {
  width: 380px;
  height: auto;
  margin-right: 50px;
}

@media screen and (max-width: 1024px) {
  #about.content {
    width: 100%;
    margin: 10px 20px 20px 20px;
    display: block!important;
  }

  .profile-img-container {
    text-align: center;
  }

  #about .profile-img {
    width: 100%;
    margin: 0 0 20px 0;
  }
}