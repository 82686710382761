.mdl-popover {
  min-width: 150px;
  position: absolute;
  right: 32px;
  top: 54px;
  z-index: 99999999999999999999999999;
  transition: all 0.25s ease-out;
  transform: scale(0);
  transform-origin: 100% 0;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  line-height: normal;
  -webkit-padding-start: 0;
  list-style: none;
  display: none;
  border-radius: $border-radius;
}

.mdl-popover {
  @extend .mdl-color--grey-100;
  .mdl-popover__item {
    transition: background-color .5s linear;
    padding: 10px;
    cursor: pointer;
    display: block;
    width: 100%;
    font-weight: bold;
    color: $primary-1;
    &:hover {
      text-decoration: none;
      color: $primary-2;
    }
  }
}

.mdl-popover:hover ul { transform: scale(1); }

.mdl-popover.active {
  display: block;
  transform: scale(1);
  transition: all 0.25s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}

#edit-popover {
  right: 45px;
  top: 95px;
}
