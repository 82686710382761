#password_reset{
	ul{
		margin: 10px;
		color: #E20B53;
		font-weight: bold;
		&.success{
			color: #9100FF
		}
	}
}
.forgot-password{
	font-weight: bold;
	margin-top: 10px;
	color: #E20B53;
	line-height: 3;
	&:hover{
		color: #F97F13;
	}
}