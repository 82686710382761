#news.content {
  margin-top: 30px;
  @extend .flex;
  @extend .flex-vertical;
  flex: 1;
}

#news .content-group {
  @extend .flex;
  flex: 1;
}

.news-container {
  position: relative;
  @extend .flex;
  @extend .flex-vertical;
  height: 605px;
}

/* to make scrollbars always visible */
/*.always-visible.ps-container > .ps-scrollbar-x-rail,
.always-visible.ps-container > .ps-scrollbar-y-rail {
  opacity: 0.6;
}*/

.ps-container.ps-in-scrolling.ps-y>.ps-scrollbar-y-rail {
  background-color: $bg;
  opacity: 1;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: $bg;
  opacity: 1; }


.news-item {
  width: calc(100% - 15px);
  @extend .flex;
  @extend .flex-vertical;
  @extend .justify-content-flex-start;
  margin-top: 50px;
  img {
    @extend .marg-btm-10px;
    width: 100%;
    height: auto;
  }
  .iframe-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
}

.news-title {
  margin-bottom: 10px;
}

.news-item:first-child {
  margin-top: 0;
}

.latest-extra {
  flex: 1 0 320px;
  margin-left: 50px;
  margin-right: -5px;
}

.press-links {
  margin-left: 5px;
  a {
    font-size: 14px;
  }
}

.album-heading {
  margin: 20px 5px 10px;
}

.album-container {
  @extend .flex;
  @extend .flex-horizontal;
  @extend .flex-wrap;
  a {
    position: relative;
    width: 75px;
    height: 75px;
    overflow: hidden;
    padding: 5px;
    margin: 0 5px 5px 0;
    &:nth-child(4n) {
      margin: 0 0 5px 0;
    }
  }
  img {
    @include center();
  }
}

/*.instagram {
  margin-bottom: 45px;
}*/

@media screen and (max-width: 1024px) {
  #news .content-group {
    display: flex;
    display: -webkit-flex; /* Safari */
    flex-flow: column-reverse wrap;
    -webkit-flex-flow: column-reverse wrap; /* Safari 6.1+ */
    justify-content: flex-start;
    -webkit-justify-content: flex-start; /* Safari 6.1+ */
  }

  .latest-extra {
    margin-left: 0;
    -webkit-box-flex: 1;   /* OLD - iOS 6-, Safari 3.1-6 */
    width: 100%;            /* For old syntax, otherwise collapses. */
    -webkit-flex: 1;       /* Safari 6.1+. iOS 7.1+, BB10 */
    -ms-flex: 1;           /* IE 10 */
    flex: 1;               /* NEW, Spec - Firefox, Chrome, Opera */
  }

  .news-container {
    height: 100%;
    margin: 10px 5px;
  }
  .news-item {
    width: 100%;
  }
}


