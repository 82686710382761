@import "../../mdl/variables";

.uploadcare-widget {
  display: flex;
}

.uploadcare-widget .uploadcare-widget-button,
.uploadcare-widget .uploadcare-widget-button.uploadcare-widget-button-remove,
.uploadcare-widget .uploadcare-widget-text {
  display: none!important;
}

.uploadcare-widget .uploadcare-widget-text {
  margin: 0 10px;
}

.uploadcare-widget-status-loaded {
  display: none;
}

.uploadcare-widget-status-error {
  color: rgb(222, 50, 38);
}

.uploadcare-link:hover {
  color:  unquote("rgb(#{$color-primary})");
}

.uploadcare-link, .uploadcare-link:link, .uploadcare-link:visited {
  border-bottom: none;
  color: #777;
}