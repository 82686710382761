/* Variables here */

/* Colors */

$body: #363636;

/*$primary-1: #D60043;
$primary-1: #FF0054;*/
$primary-1: #E20B53;
$primary-2: #632AB2;
$primary-2: #9100FF;
$primary-3: #3A86FF;

$accent-1: #F97F13;
$accent-3: #E7CD13;
$accent-2: #02b7ae;
$accent-4: #9CBAEB;
$accent-5: #F59DB3;

$bg: #DEDEDE;

$layout_width : 950px;
$layout-width-margin: calc(950px - 20px);

$shape_primary_size: 34px;

$input-color-border: #d0d0d0 !default;
$input-font-size: 13px !default;
$input-line-height: 18px !default;
$input-border: 1px solid $input-color-border !default;
$input-border-radius: 3px !default;
$input-padding: 8px !default;
$input-color-text: #303030 !default;
$input-focus-border: $primary-1;

$border-radius: 3px;

/* Transition Mixin */

@mixin transition($duration) {
	transition: all $duration cubic-bezier(.27,.63,.5,1.05);
}
