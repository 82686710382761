#dashboard {
  .xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
    background: $primary-1 !important;
  }

  .xdsoft_datetimepicker .xdsoft_calendar td:hover,
  .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div:hover {
    background: $primary-1 !important;
  }

  .xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
    background: $primary-3 !important;
    box-shadow: none;
    color: white;
  }

  .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
    color: #666;
    font-weight: bold;
  }

  .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
  .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
  .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current {
    background: $primary-3;
    box-shadow: none;
  }
}