/*
EFFECTS
*/

/*HOVER EFFECTS*/

/* Common style */
figure {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

figure img {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}

figure figcaption {
  padding: 0;
  color: $body;
  opacity: 0;
}

figure figcaption::before,
figure figcaption::after {
  pointer-events: none;
}

figure figcaption,
figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

figure h5 {
  margin: 0;
}

figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  opacity: 0;
}

.color-1-bg::before {
  background: $accent-1;
}

.color-2-bg::before {
  background: $accent-2;
}

.color-3-bg::before {
  background: $accent-3;
}

.color-4-bg::before {
  background: $primary-1;
}

.color-5-bg::before {
  background: $primary-2;
}

.color-6-bg::before {
  background: $primary-3;
}

.color-7-bg::before {
  background: $accent-4;
}

.color-8-bg::before {
  background: $accent-5;
}

figure h5 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  color: $body;
  transform: translate(-50%, -50%);
}

figure.hover figcaption,
figure.hover figcaption::before,
figure:focus figcaption,
figure:focus figcaption::before,
figure:hover figcaption,
figure:hover figcaption::before {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  figure:hover figcaption,
  figure:hover figcaption::before {
    opacity: 0;
  }
}