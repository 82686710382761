// Main layout class.
#dashboard {
  .mdl-layout__container {
    overflow: hidden;
  }
  .mdl-layout {
    position: initial;
    overflow: hidden;
  }
  .mdl-layout__content {
    z-index: 0;
    width: calc(100% - 240px);
    margin-top: 0;
    background-color: #FFF;
  }
  .mdl-layout__header-row {
    height: 70px;
    @extend .flex-horizontal;
    @extend .justify-content-space-between;
    .selectize-input {
      width: 300px;
    }
    .button {
      margin-left: 16px;
    }
  }
  hr {
    border-color: $input-color-border;
  }
  .index-container {
    position: relative;
    width: calc((316px *3) + 16px);
    margin: $grid-desktop-margin/2 auto;
    @extend .flex;
    flex-wrap: wrap;
    @extend .flex-center;
    .thumbnail-container {
      margin: $grid-desktop-margin/2;
    }
    .thumbnail-container.ss-active-child {
      position: absolute;
    }
  }
  .form-controls {
    @extend .flex;
    @extend .justify-content-space-between;
    padding: $grid-desktop-margin;
    transition: all .5s linear;
  }
  .news-item {
    width: 100%;
    margin-right: 0;
    a {
      text-decoration: underline;
    }
  }
  .news-item-actions {
    @extend .flex;
    @extend .justify-content-flex-end;
    .button {
      margin-left: $grid-desktop-margin;
    }
  }
  .modal {
    margin: 100px;
  }
  #cloned {
    .thumbnail-container {

    }
  }
  .note-editable {
    .iframe-container {
      width: 100%;
      margin: 0 auto 10px auto;
      iframe {
        position: relative;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
}

#album-images-container {
  .mdl-cell {
    img {
      width: 100%;
      height: auto;
    }
  }
}