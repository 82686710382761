/*FLEX*/
.flex {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.flex-vertical {
  -webkit-flex-direction: column; /* Safari 6.1+ */
  -webkit-box-orient: vertical;
  flex-direction: column;
}

.flex-horizontal {
  -webkit-flex-direction: row;
  -webkit-box-orient: horizontal;
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-self-flex-start {
  align-self: flex-start;
}

.align-self-flex-end {
  align-self: flex-end;
}

.flex-0 {
  -webkit-box-flex: 0;      /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 0;         /* OLD - Firefox 19- */
  /*width: 20%;               /* For old syntax, otherwise collapses. */
  -webkit-flex: 0;          /* Chrome */
  -ms-flex: 0;              /* IE 10 */
  flex: 0;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.flex-1 {
  -webkit-box-flex: 1;      /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1;         /* OLD - Firefox 19- */
  /*width: 20%;               /* For old syntax, otherwise collapses. */
  -webkit-flex: 1;          /* Chrome */
  -ms-flex: 1;              /* IE 10 */
  flex: 1;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.flex-2 {
  -webkit-box-flex: 2;      /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 2;         /* OLD - Firefox 19- */
  /*width: 20%;               /* For old syntax, otherwise collapses. */
  -webkit-flex: 2;          /* Chrome */
  -ms-flex: 2;              /* IE 10 */
  flex: 2;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.order-1 {
  order: 1;
}