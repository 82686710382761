.tooltip-arrow {
  display: none;
}

.tooltip-inner {
  border-radius: 0;
  max-width: 300px;
}

.tooltip.right {
  margin-left: -15px;
  margin-top: -40px;
}

.tooltip.top {
  visibility: hidden;
}

.tooltip-inner {
  background-color: $primary-2;
}

.tooltip.in {
  filter: alpha(opacity=100);
  opacity: 1;
}