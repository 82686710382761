.chosen-container-single {
  .chosen-single {
    background: none;
    padding: 8px;
    height: auto;
    font-size: 13px;
    line-height: 18px;
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    box-shadow: none;
  }
}

.chosen-container {
  .chosen-drop {
    border: 1px solid #D0D0D0;
  }
  .chosen-results li.highlighted {
    background-color: $primary-1;
    background-image: none;
    color: #fff;
  }
}

.chosen-container-active.chosen-with-drop .chosen-single {
  border: 1px solid #D0D0D0;
  background-image: none;
  box-shadow: 0 1px 0 #fff inset;
}

.chosen-container-single .chosen-search input[type="text"] {
  border: 1px solid #D0D0D0;
  border-radius: 2px;
}

.chosen-container-active .chosen-single {
  border: 1px solid #D0D0D0;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.3);
}

.chosen-container-single .chosen-single div b {
  background: url('chosen-sprite.png') no-repeat 0px 6px;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -17px 6px;
}