.fancybox-skin {
  border-radius: 0;
  background: transparent;
}
.fancybox-opened .fancybox-skin {
  box-shadow: none;
}

/*.fancybox-lock .fancybox-overlay {
  background: #A0A0A0;
  background: rgba(160,160,160,0.95);
}*/

.fancybox-outer .material-icons {
  font-size: 50px;
  color: #FFF;
}

.fancybox-close {
  top: -35px;
  right: -35px;
}

.fancybox-close .material-icons {
  font-size: 30px;
  color: #FFF;
}