#contact.content {
  margin-top: 30px;
  @extend .flex;
  @extend .flex-vertical;
}

#contact .content-group {
  @extend .flex;
}

#contact .content-group-1 {

}

#contact .content-group-2 {
  @extend .flex;
  @extend .flex-vertical;
  margin-left: 25px;
  margin-top: 25px;
  /*@extend .justify-content-space-between;*/
  /*@extend .justify-content-center;*/
}

.chat {
  padding-left: 2px;
  margin-bottom: 20px;
}

#contact .contact-sub-header {
  margin-bottom: 7px;
}



.group-header {
  @extend .weight-600;
  @extend .text-uppercase;
  padding-bottom: 10px;
}

.contact-item {
  margin: 12px 0;
}

.contact-item:last-child {
  margin: 12px 0 0 0;
}

.digital-group .contact-item,
.other-group .contact-item {
  /*margin-top: 20px;*/
  margin-top: 0;
  margin-bottom: 20px;
}

#contact h5 {
  @extend .marg-btm-20px;
}

#contact img {
  width: 520px;
}

@media screen and (max-width: 760px) {
  #contact.content {
    width: 100%;
    margin: 20px;
    display: block!important;
  }

  #contact .content-group {
    display: block!important;
    height: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  #contact .content-group-2 {
    margin-left: 0;
  }

  #contact .contact-sub-header,
  #contact .contact-item {
    margin-bottom: 20px;
  }

  #contact img {
    width: 100%;
    height: auto;
  }
}