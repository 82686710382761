/*
COLOR
*/

body {
  color: $body;
  background-color: $bg;
}

.mdl-layout__header-row {
  background-color: transparent;
}

a {
  /*color: $text-color-primary;*/
  color:$body;
}

.brand {
  color: #FFF;
}

.mdl-layout__header {
  /*color: $text-color-primary;*/
  color: #FFF;
}

.mdl-layout__drawer {
  .active.type-0.md-sm,
  .type-0.md-sm:hover {
    color: $primary-1;
  }
}
/*Type 0 - Show All*/

a.type-0:hover,
.active.type-0,
footer .type-0 a:hover {
  color: $accent-1;
}

.row-1.type-0-primary-bg {
  background-color: $primary-3;
}

.row-1.type-0-primary-bg.opacity-40 {
  @extend .opacity-70;
}

.square.type-0-bg.opacity-60 {
  @extend .opacity-0;
  background-color: lighten(#e29580, 2%);
}

.row-2.type-0-primary-bg {
  background-color: $primary-2;
}

.row-3.type-0-primary-bg {
  background-color: $primary-1;
}

.square.type-0-bg {
  background-color: $accent-1;
}

.lnk-back.type-0-bg:hover {
  background-color: $accent-1;
}

.type-0-border {
  border-bottom: 2px solid $accent-1;
}

/*Type 1 - Commercial*/
a.type-1:hover,
.active.type-1,
.hover.type-1,
footer .type-1 a:hover,
.accent-1-col {
  color: $accent-1;
}

.type-1-primary-bg,
.primary-1-bg {
  background-color: $primary-1;
}

.type-1-bg,
.accent-1-bg {
  background-color: $accent-1;
}

.lnk-back.type-1-bg:hover {
  background-color: $accent-1;
}

.type-1-border {
  border-bottom: 2px solid $accent-1;
}

.horizontal-row.row-3.type-2-primary-bg {

}

/*Type 2 - Long Form*/
a.type-2:hover,
.active.type-2,
footer .type-2 a:hover {
  color: $accent-2;
}

.type-2-primary-bg,
.primary-2-bg {
  background-color: $primary-2;
}

.type-2-bg:hover,
.type-2-bg,
.accent-2-bg {
  background-color: $accent-2;
}

.lnk-back.type-2-bg:hover {
  background-color: $accent-2;
}

.type-2-border {
  border-bottom: 2px solid $accent-2;
}

/*Type 3 - Photography*/
a.type-3:hover,
.active.type-3,
footer .type-3 a:hover {
  color: $accent-3;
}

.type-3-primary-bg,
.primary-3-bg {
  background-color: $primary-3;
}

.type-3-bg,
.accent-3-bg {
  background-color: $accent-3;
}

.lnk-back.type-3-bg:hover {
  background-color: $accent-3;
}

.type-3-border {
  border-bottom: 2px solid $accent-3;
}

.active.hover {
  color: #000;
}

.mdl-layout_header-sub a {
  color: white;
}

.bg-color {
  background-color: darken( $bg, 5% );
}

.lnk-back:hover,
.lnk-back:active,
.lnk-back:focus {
  color: #FFF;
}

.lnk-back {
  background-color: $body;
  color: #FFF;
}


.news .news-item,
.news .news-item {
  border-bottom: 1px solid lighten($body, 50%);
}

.border-bottom-1px {
  border-bottom: 1px solid $body;
}

.lnk-close {
  color: $body;
}

#about .vertical-line {
  background-color: $primary-1;
}

#news .vertical-line {
  background-color: $primary-2;
}

#news a {
  color: $primary-2;
}

.fancybox-title a {
  color: $primary-2;
  &:hover {
    color: $accent-2;
  }
}

#news a h6 {
  color: $body;
}

#news a h6:hover,
#news a:hover {
  color: $accent-2;
}

#contact .vertical-line {
  background-color: $primary-3;
}

#contact a,
.lnk-photography {
  color: $primary-3;
}

#contact a {
  padding: 0 2px;
}

#contact a:hover {
  background-color: $accent-3;
}

.lnk-photography:hover {
  color: $accent-3;
}

#contact .material-icons {
  color: $primary-3;
}

.mdl-menu__outline{
  background: $bg;
}

#instagram-album a,
#on-location-album a {
  border: 5px solid $bg;
}

#instagram-album a:hover,
#on-location-album a:hover {
  border: 5px solid $accent-2;
}
