$button-font-size: 13px;
$button-color-text: #FFF !default;
$button-color-border: #000 !default;
$button-border: 0px solid $button-color-border !default;

$icon-button-font-size: 30px;
$button-link-font-size: 20px;

.button:hover {
	text-decoration: none;
	color: $button-color-text;
	background-color: $primary-2;
}

.button {
	cursor: pointer;
	max-width: 200px;
	padding: 8px 16px;
	background-color: $primary-1;
	border-radius: $border-radius;
	text-align: center;
	font-family: inherit;
	font-size: $button-font-size;
	font-weight: bold;
	text-transform: uppercase;
	color: $button-color-text;
	border: $button-border;
	transition: background-color .5s linear;
	&.icon-button {
		padding: 0;
		background-color: transparent;
		i {
			transition: color .5s linear;
			font-size: $icon-button-font-size;
			color: $primary-2;
			&:hover {
				color: $primary-3;
			}
		}

	}
	&.icon-label-button {
		@extend .flex;
		@extend .flex-center;
		i {
			font-size: $button-font-size + 6;
		}
	}
}

.button-link {
	@extend .flex;
	@extend .flex-center;
	line-height: normal;
	font-size: $button-link-font-size;
	max-width: 150px;
	transition: color .5s linear;
	cursor: pointer;
	font-weight: bold;
	color: $primary-1;
	&:hover {
		color: $primary-2;
	}
	i {
		font-size: $icon-button-font-size;;
	}
}

.layout-absolute-button {
	position: fixed;
	right: 0;
	bottom: 0;
	margin: 16px;
}