/*
HELPER
*/

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.center-x {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.width-60 {
  width: 50%;
}

.width-60 {
  width: 60%;
}


/*TEXT ALIGNMENT*/

.weight-300 {
  font-weight: 300;
}

.weight-600 {
  font-weight: 600;
}

.weight-italic-300 {
  font-weight: 300;
  font-style: italic;
}

.weight-italic-400 {
  font-weight: 400;
  font-style: italic;
}

.weight-italic-700 {
  font-weight: 700;
  font-style: italic;
}

.italic-300 {
  font-weight: 300;
  font-style: italic;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-justify-last {
  -moz-text-align-last: justify;
  text-align-last: justify;
}

.text-justify-last:after {
  content: "";
  display: inline-block;
  width: 100%;
}

/*FONT SIZES*/
.font-16px {
  font-size: 16px;
}

/*MARGINS & PADDING*/

.marg-0-auto {
  margin: 0 auto;
}

.marg-10px-auto {
  margin: 10px auto;
}

.marg-10px-0 {
  margin: 10px 0;
}

.marg-top-10px {
  margin-top: 10px;
}

.marg-btm-10px {
  margin-bottom: 10px;
}

.marg-btm-20px {
  margin-bottom: 20px;
}

.marg-lft-10px {
  margin-left: 10px;
}

.marg-10px {
  margin: 10px;
}

.marg-20px {
  margin: 20px;
}
/*
Padding
*/
.pad-2 {
  padding: 2em;
}

.pad-top-40 {
  padding-top: 20px;
}

.pad-btm-40 {
  padding-bottom: 20px;
}

.pad-rgt-20px {
  padding-right: 20px;
}

.pad-btm-20px {
  padding-bottom: 20px;
}

/*DIMENSIONS*/

.height-2 {
  height: 2em;
}

/* Transition Mixin */

@mixin transition($duration) {
  transition: all $duration cubic-bezier(.27,.63,.5,1.05);
}

