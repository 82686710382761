.graphics-container {
  @extend .flex;
  @extend .justify-content-flex-start;
  height: 100%;
  flex: 0 1 (5 * $shape_primary_size);
}

.vertical-line {
  flex: 0 1 $shape_primary_size;
  height: 100%;
}

.square-container {
  @extend .flex;
  @extend .flex-horizontal;
}

.square {
  width: $shape_primary_size;
  height: $shape_primary_size;
}

.instagram .square {
  margin: 10px;
  width: 100px;
  height: 100px;
}

hr {
  width: 100%;
  border-top: 1px solid $body;
}

@media screen and (max-width: 1024px) {
  .graphics-container {
    flex-flow: column;
  }
}
