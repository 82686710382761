.thumbnail-container {
  position: relative;
  width: 300px;
  height: 169px;
  background-color: #EEE;
  /*&.animated {
    -webkit-transition: left 0.4s ease-in-out;
    -moz-transition: left 0.4s ease-in-out;
    transition:left 0.4s ease-in-out;
  }*/
  &.square {
    min-height: 250px;
    width: 250px;
    @include aspect-ratio(1, 1);
    .content {
      display: block;
      margin: 0;
    }
  }
  img {
    width: 100%;
    height: auto;
    opacity: 0.6;
  }
  .button-container {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    margin: 0;
    @extend .flex;
    @extend .flex-vertical;
    @extend .flex-center;
    .title {
      text-align: center;
      margin-bottom: $grid-desktop-margin;
    }
  }
  &.is-draggable:hover {
    outline: 5px solid $primary-3;
    cursor: pointer;
  }
}

.image-container {
  position: relative;
  width: 100%;
  background-color: #EEE;
  &.square {
    padding-bottom: 100%;
  }
  img {
    width: 100%;
    height: auto;
    opacity: 0.6;
  }
  .button-container {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    margin: 0;
    @extend .flex;
    @extend .flex-vertical;
    @extend .flex-center;
  }
}
