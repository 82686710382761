.mdl-grid {
  justify-content: flex-start;
}

.mdl-cell {
	border-radius: 2px !important;
  	margin: 10px;
}

@media (min-width: 840px) {
  .mdl-cell--4-col {
    width: calc(33.33333% - 20px);
  }
}

.iframe-container {
  margin:0 auto 10px auto;
  width: calc(100% - 20px);
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

label {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.closeMobile {
  margin-top: -50px;
}

hr {
  width: 200px;
  margin: 0 auto;
}

img {
  height: auto;
}

.profile-image {
  width: 100%;
}

.mdl-tooltip {
  background: darken($bg, 50%);
  border-radius: 0;
  font-size: 12px;
}

ul.pagination {
  width: calc(100% - 15px);
  margin-top: 50px;
  @extend .flex;
  @extend .justify-content-space-between;
  list-style-type: none;
}

ul.pagination li {

}

.hidden-lg {
  display: none;
}

.modal-backdrop {
  display: none;
}

@media screen and (max-width: 1024px) {
  .hidden-lg {
    display: block;
  }

  .mdl-grid {
    justify-content: space-around;
  }
}

.error {
  font-size: 12px;
  padding-top: 5px;
  text-align: left;
  color: red;
}